import React, { useContext } from "react"
import { PageLayout } from "../components"
import { Container, Accordion } from "react-bootstrap"
//import {  graphql } from "gatsby"
import { SEO } from "../utils"
import { Link } from "@reach/router"

import { Line } from 'react-chartjs-2';
import ThemeContext from "../utils/theme"


export default ({ data }) => {

  const { dark } = useContext(ThemeContext)

  let points = []
  let labelPoints = []
  let axisColor = []
  let axisWidth = []

  for(let i = -0.80; i <= 0.80; i+=0.1)
  {
    let y = ( 500*(1-2*(Math.pow(i,2)) ))
    points.push({"x":String((i.toFixed(2))),"y":String((y.toFixed(2)))})
    labelPoints.push(String((i.toFixed(2))))
    if (Math.abs(Math.round(i * 1000)) == 0)
    {
      axisColor.push("grey")
      axisWidth.push(3)
    }
    axisColor.push("black")
    axisWidth.push(0.5)
  }
  //console.log("points",points, "|colors",axisColor)



  const dataParam = 
  {
    labels: labelPoints,
    datasets: [
      {
        label: 'f(x)',
        data: points,
        fill: false,
        backgroundColor: 'rgb(0, 221, 0)',
        borderColor: 'rgba(0, 221, 0, 0.5)',
        borderWidth: 4,
        tension:0.3,
      }
    ],
  };

  const options = {
    parsing: {
      xAxisKey: "x",
      yAxisKey: "y"
    },
    plugins:{
      legend:{
        display: false,
      },
    },
    scales: {
      y: {
        min: -100,
        max: 600,
        grid:{
          color:["black","grey","black","black","black","black","black"],
          lineWidth: [0.5,3,0.5,0.5,0.5,0.5,0.5],
        },
        ticks:
        {
          font:
          {
            size:10,
            weight:"900",
          }
        }
      },
      x:{
        grid:{
          color:axisColor,
          lineWidth: axisWidth,
        },
        ticks:
        {
          font:
          {
            size:10,
            weight:"900",
          }
        }
      }
    },
    responsive : true,
    maintainAspectRatio: false
  };


  return (
    <PageLayout>
      <SEO title="About" />
      <Container className="d-flex align-items-center justify-content-center" >
        <div className=" text-color" style={{ maxWidth: "800px",paddingLeft:"0px !",paddingRight:"0px !" }}>
          
          <h1> <u>About</u> </h1>
          <br/><br/>

          <Accordion>
            <Accordion.Item eventKey="0" style={{borderColor:'#bbb',borderWidth:'1px'}}>
              <Accordion.Button style={{backgroundColor:`${dark ? '#222' : 'white'}`,color:`${dark ? 'white' : 'black'}`,fontWeight:'900',fontSize:'1.2rem' }}>
                <u> What is Appraisir:</u>
              </Accordion.Button>
              <Accordion.Body >
              <h5 style={{textAlign:"left"}}> 
                <ul>
                  <li>
                    Appraisir is the first ever real estate game. You are shown a house listing and have to use the home details to figure out the price. 
                    Use the Hint button if the price is too difficult to guess.   
                  </li>
                </ul>
              </h5>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="1" style={{borderColor:'#bbb',borderWidth:'1px'}}>
              <Accordion.Button style={{backgroundColor:`${dark ? '#222' : 'white'}`,color:`${dark ? 'white' : 'black'}`,fontWeight:'900',fontSize:'1.2rem' }}>
                <u> How to play:</u>
              </Accordion.Button>
              <Accordion.Body >
              <h5 style={{textAlign:"left"}}> 
                <ul>
                  <li>
                    Go to the <Link style={{color:"#00DD00"}} to="/">home page</Link> and click on any of the homes. 
                  </li>
                  <br/>
                  <li>
                    Look through the photos and details to get an idea of everything the home has to offer.
                  </li>
                  <br/>
                  <li>
                    Guess the price of the home by inputting a number into the top text bar!
                  </li>
                  <br/>
                  <li>
                    <span style={{color:"#00DD00"}}>Create an account to keep track of your score.</span>
                  </li>
                </ul>
              </h5>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="2" style={{borderColor:'#bbb',borderWidth:'1px'}}>
              <Accordion.Button style={{backgroundColor:`${dark ? '#222' : 'white'}`,color:`${dark ? 'white' : 'black'}`, fontWeight:'900',fontSize:'1.2rem' }}>
                <u> How score is calculated:</u>
              </Accordion.Button>
              <Accordion.Body >
        
                <h5 style={{textAlign:"center"}}> 
                  The formula is y=500(1-(2x&sup2;)), where x = (|Guess-Price| / Price)
                </h5>
                {
                //   <Image
                //   width="900"
                //   height="900"
                //   fluid
                //   src={`../../icons/ScoringGraph.png`}
                //   alt={"Formula"}
                // />
                }
                <div className="graphScore">
                  <Line data={dataParam} options={options} />
                </div>
                <br/>
                <h5 style={{textAlign:"left"}}> 
                  The X axis is the percentage away from the actual price, and the Y axis is the scored points. Since we take the 
                  absolute value of the ( Guess - Price ), x will always be positive. The maximum score is 500 and the score is rounded up, so
                  a y value of 499.51 is rounded to 500. 
                  <br/> <br/>
                  The Average Home Price helps in guessing the right price. However, unlocking this will lower your score by 100.
                  In terms of the formula, it is a simple subtraction from your score ( new score = score - 100 ).
                  <br/>
                  Similarly, unlocking the Tax Assessed Value helps in guessing the price, but will lower your score by 50.
                </h5>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="3" style={{borderColor:'#bbb',borderWidth:'1px'}}>
              <Accordion.Button style={{backgroundColor:`${dark ? '#222' : 'white'}`,color:`${dark ? 'white' : 'black'}`,fontWeight:'900',fontSize:'1.2rem' }}>
                <u> Is Appraisir free to play:</u>
              </Accordion.Button>
              <Accordion.Body >
              <h5 style={{textAlign:"left"}}> 
                <ul>
                  <li>
                    Yes, Appraisir is currently free to play! I am supported by the ads shown on the website.  
                  </li>
                </ul>
              </h5>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="4" style={{borderColor:'#bbb',borderWidth:'1px'}}>
              <Accordion.Button style={{backgroundColor:`${dark ? '#222' : 'white'}`,color:`${dark ? 'white' : 'black'}`,fontWeight:'900',fontSize:'1.2rem' }}>
                <u> About the developer:</u>
              </Accordion.Button>
              <Accordion.Body >
              <h5 style={{textAlign:"left"}}> 
                <ul>
                  <li>
                    Appraisir is made by one developer. Unfortunatly, this site makes little money. I update the game in my free time, while also working full time. 
                  </li>
                </ul>
                <ul>
                  <li>
                    I hope that Appraisir becomes popular enough to support me full 
                    time. <a href={`https://www.twitter.com/appraisir`} className="linkColor" target="_blank" style={{color:'#1DA1F2'}}>Tweet</a> me 
                    suggestions and recommendations you have for the site!
                  </li>
                </ul>
              </h5>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>  
          <br/>
          <br/>
          <br/>
          <h4 style={{textAlign:"left"}}> 
            Appraisir is developed by one person. I am supported by the Ads displayed on this site. 
            <span style={{color:"#00DD00"}}> Please disable adblock if possible! </span>
          </h4>
          <br/>
          <br/>

          <hr style={{ borderTop:`1px solid grey`}}/>

          

        </div>
      </Container>
    </PageLayout>
  )
}
